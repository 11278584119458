import React from 'react';
import { graphql } from 'gatsby';

import { Page } from 'components/_shared/Page';
import { Markdown } from 'components/_shared/Markdown';

export default function PagePrivacyPolicy(props) {
  const { page } = props.data;

  return (
    <Page
      className='privacy-policy'
      withContainer
      containerWidth='m'
      meta={{ title: page.seo_title, description: page.seo_description }}
      withConnectSection={false}
    >
      <article>
        <h1 className='font-title text-3xl font-bold text-center lg:text-4xl lg:leading-8'>
          {page.heading}
        </h1>
        <Markdown className='mt-12' markdown={page.content.data.content} />
      </article>
    </Page>
  );
}

export const query = graphql`
query PagePrivacyPolicyQuery {
  page: strapiPrivacyPolicyPage {
    seo_title
    seo_description
    heading
    content {
      data {
        content
      }
    }
  }
}

`;
